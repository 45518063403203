import APIService from './APIService';

export default {
  getData() {
    return APIService.apiCall().get(`/indger-exports/index`);
  },
  salvarExportacao(postData) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post('/indger-exports/salvar', JSON.stringify(postData))
        .then((response) => resolve(response.data))
        .catch((response) => reject(response.data));
    });
  },
  editarExportacao(postData) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().put(`/indger-exports/editar/${postData.id}`, JSON.stringify(postData))
        .then((response) => resolve(response.data))
        .catch((response) => reject(response.data));
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().delete(`/indger-exports/delete/${id}`)
        .then((response) => resolve(response.data))
        .catch((response) => reject(response.data));
    });
  },
  getExportacaoById(id) {
    return APIService.apiCall().get(`/indger-exports/get/${id}`,)
  },
  getLinkDownload(id) {
    return APIService.apiCall().get(`/indger-exports/download/${id}`);
  },
  executarExportacao(id) {
    return APIService.apiCall().get(`/indger-exports/executar/${id}`);
  },
  verificarDisponibilidade(id) {
    return APIService.apiCall().get(`/indger-exports/disponibilidade`);
  }
}